import React from 'react'
import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';

const StickyMenu = ({item}) => {
    const [elem, setElem] = useState();
    
    useEffect(() => {
        if(item){
            setElem(item[0])
        }
    }, [item])

  return (
    elem &&
    <div className="header-nav-left">
        <button className="dropdown-category dropdown-category-2">
            <i className="iconly-Category icli"></i>
            {elem.value ?
                <NavLink to={elem.value} target={elem.type}>{elem.title}</NavLink>
                :
                <span>{elem.title}</span>
            }
        </button>

        <div className="category-dropdown">
            <div className="category-title">
                <h5></h5>
                <button type="button" className="btn p-0 close-button text-content">
                    <i className="fa-solid fa-xmark"></i>
                </button>
            </div>
            {elem.items &&
            <ul className="category-list">
                {elem.items.map((it, index) => 
                    <li className="onhover-category-list" key={index}>
                        <NavLink to={it.value} target={it.type} className="category-name">
                            <h6>{it.title}</h6>
                            <i className="fa-solid fa-angle-right"></i>
                        </NavLink>
                        {it.items &&
                        <div className="onhover-category-box">
                            {it.items.map((el, index) => 
                                <div className="list-1" key={index}>
                                    <NavLink className="category-title-box" to={el.value}>
                                        <h5>{el.title}</h5>
                                    </NavLink>
                                    <ul className='mb-3'>
                                        {el.items?.map((elem, index) =>
                                            <li key={index}>
                                                <NavLink to={elem.value} target={elem.type}>{elem.title}</NavLink>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            )}
                        </div>}
                    </li>
                )}
            </ul>}
        </div>
    </div>
  )
}

export default StickyMenu