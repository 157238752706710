import React from 'react'
import Slider from "react-slick";
import img from "../../images/placeholder.png"
import { NavLink } from 'react-router-dom';

const BestDeals = ({elem}) => {
    const settings = {
        arrows: true,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [{
                breakpoint: 1300,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 757,
                settings: {
                    slidesToShow: 1,
                    fade: true,
                }
            },
        ]
    }

  return (
	<section>
		<div className="container-fluid-lg">
			<div className="title">
				<h2>{elem.title}</h2>
			</div>
			<div className="row">
				<div className="col-12">
					{elem.elements && 
                    <Slider className="three-slider arrow-slider ratio_65" {...settings}>
                        {elem.elements?.map((card, index) =>
                            <div key={index}> 
                                <div className="offer-banner hover-effect">
                                    <img src={card.front_image} effect="blur" className="img-fluid bg-img" alt=""
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; 
                                            currentTarget.src = img
                                        }}
                                    />
                                    {card.description && <div className="banner-detail" 
                                        dangerouslySetInnerHTML={{__html: card.description}}
                                    ></div>}
                                    <div className="offer-box">
                                        {card.link && <NavLink to={card.link} className="btn-category btn theme-bg-color text-white">{card.button_text}</NavLink>}
                                    </div>
                                </div>
                            </div>
                        )}
					</Slider>}
				</div>
			</div>
		</div>
	</section>
  )
}

export default BestDeals